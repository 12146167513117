<template>
  <div class="d-flex justify-content-center align-items-center">
    <template v-if="showProcess == 2">
      <img src="@/static/image/solu-per-4.png" alt="" />
    </template>
    <template v-if="showProcess == 3">
      <img src="@/static/image/solu-company-2.png" alt="" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    showProcess: String,
  },
};
</script>

<style>
</style>