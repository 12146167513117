<template>
  <div class="wrap">
    <!-- 0 -->
    <div class="row">
      <div class="row-items">
        <div
          v-for="(item, index2) in solutionItems[index]"
          :key="index2"
          class="row-item-row"
        >
          <div class="row-item">
            <div class="text-white position-absolute" style="top: 0;left:0;right:0;">
              {{ item.big_title }}
            </div>
            <div class="row-item-content">
              <h3 class="row-item-title">{{ item.title }}</h3>
              <div class="row-item-description">
                {{ item.text }}
              </div>
            </div>
            <img class="w-100" :src="item.src" alt="" />
          </div>
          <template v-if="item.showProcess === 2">
            <div
              class="row-item d-flex justify-content-center align-items-center"
            >
              <div class="row-item-content-showProcess container">
                <solution-item-process showProcess="2"></solution-item-process>
              </div>
              <img
                class="w-100"
                src="@/static/image/solu-company-4.png"
                alt=""
              />
            </div>
          </template>
          <template v-if="item.showProcess === 3">
            <div
              class="row-item d-flex justify-content-center align-items-center"
            >
              <div class="row-item-content-showProcess container">
                <solution-item-process showProcess="3"></solution-item-process>
              </div>
              <img
                class="w-100"
                src="@/static/image/solu-company-4.png"
                alt=""
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import solutionItemProcess from "@/components/solution-item-process.vue";
export default {
  components: {
    solutionItemProcess,
  },
  props: {
    index: Number,
  },
  watch: {
    index() {
      console.log("index", this.index);
      this.showAnimation = true;
    },
  },
  data() {
    return {
      // showAnimation: false,
      solutionItems: [
        //银行
        [
          {
            src: require("@/static/image/solu-bank-1.png"),
            title: "小微信贷风控解决方案",
            text:
              "以专业风控经验为基础，针对小微企业信贷服务需求和银行个性化需求，通过本地化部署、API接口等操作，提供贯穿贷前审批到贷后预警，欺诈风险甄别、尽职调查到授信定价支持，专属风控体系架构搭建到信贷全流程风控设计落地的全流程、个性化风控解决方案。",
          },
          {
            src: require("@/static/image/solu-bank-2.png"),
            title: "精准获客解决方案",
            text:
              "通过中壬银兴投融资智能匹配系统，帮助银行在该生态中自动过滤高危企业，筛选出符合自家产品进件条件的潜在客户，获得平台智能匹配推荐。减少无效业务占据的时间人力成本，大大提升工作效率。",
          },
        ],
        // 新金融机构
        [
          {
            src: require("@/static/image/solu-fin-1.png"),
            title: "定制化信贷风控解决方案",
            text:
              "以专业风控经验为基础，针对小微企业信贷服务需求和合作企业的个性化需求，通过本地化部署、API接口等操作，提供贯穿贷前审批到贷后预警，欺诈风险甄别、尽职调查到授信定价支持，专属风控体系架构搭建到信贷全流程风控设计落地的全流程、个性化风控解决方案。",
          },
          {
            src: require("@/static/image/solu-fin-2.png"),
            title: "开放式信贷风控解决方案",
            text:
              "以专业风控经验为基础，秉承SaaS（智能分析即服务）理念，针对小微企业信贷服务需求，从企业财报监控到流水反欺诈，贯穿智能贷前贷后全流程。通过智能预警、智能决策、智能信贷报告分析全流程，为金融机构提供标准化风控解决方案。",
          },
          {
            src: require("@/static/image/solu-fin-3.png"),
            title: "精准获客解决方案",
            text:
              "通过中壬银兴投融资智能匹配系统，帮助机构在该生态中自动过滤高危企业，筛选出符合自家产品进件条件的潜在客户，获得平台智能匹配推荐。减少无效业务占据的时间人力成本，大大提升工作效率。",
          },
        ],
        // 金融从业人员
        [
          {
            // showProcess: 2,
            src: require("@/static/image/solu-per-1.png"),
            title: "智慧决策解决方案",
            text:
              "基于中壬云债权评测系统，帮助金融从业人员如业务员、信贷员等，快速完成初筛、初审及相关决策。通过系统勾兑进件客户财报及银行流水数据，提前预警客户经营风险和流水反欺诈风险。通过一键输出的客户安全可贷金额区间值和企业信用价值报告，可视化预估客户融资额度，全面知悉客户风险。减少从业人员的道德风险和主观评估差异。",
          },
          {
            // big_title: "信贷审核流程",
            src: require("@/static/image/per-2.png"),
            title: "",
            text: "",
          },
        ],
        // 小微企业
        [
          {
            // showProcess: 3,
            src: require("@/static/image/solu-company-1.png"),
            title: "信贷融资解决方案",
            text:
              "针对小微企业的融资需求打造整体风控解决方案，提供前期智能评测，直接输出额度和信贷报告的方式帮助小微企业快速预估自身安全合理的融资额度，并通过平台自动匹配金融机构服务助其迅速成功完成融资。",
          },

          {
            // showProcess: 3,
            src: require("@/static/image/solu-company-4.png"),
            title: "信贷价值自评",
            text:
              "中壬云智能风控系统采用PVE算力的智能算法，勾兑企业财报、流水比例取值，并综合整合企业工商司法税务等信息，自动生成企业信贷价值报告，帮助企业分析自身信贷价值的优劣及原因，降低企业盲目申请带来的信用损失风险。",
          },
          {
            // showProcess: 3,
            src: require("@/static/image/company-3.png"),
            title: "",
            text: "",
          },
        ],
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.fade-in-left {
  animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.row {
  .row-items {
    animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    .row-item-row {
      &:nth-child(1) {
        .row-item-content {
          left: 15%;
        }
      }

      &:nth-child(2) {
        text-align: right;
        .row-item-content {
          right: 15%;
        }
      }
      &:nth-child(3) {
        .row-item-content {
          left: 15%;
        }
      }
      .row-item {
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          // display:block;
        }
        .row-item-content {
          cursor: pointer;
          width: 600px;
          position: absolute;
          z-index: 999;
          top: 50%;
          transform: translate(0%, -50%);
          .row-item-title {
            color: white;
            text-align: center;
            padding-bottom: 10px;
            font-size: 24px;
          }
          .row-item-description {
            color: #808cec;
            font-size: 18px;
            text-align: left;
            line-height: 2;
            text-align: justify;
            text-indent: 2em;
          }
        }
        .row-item-content-showProcess {
          cursor: pointer;
          // width: 600px;
          position: absolute;
          z-index: 999;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>