<template>
  <div class="footer position-relative" :style="bg_image">
    <div class="container">
      <div class="row d-flex justify-content-center text-white dy-padding">
        <!-- logo -->
        <div
          class="w-25 d-flex flex-column align-items-center justify-content-center"
        >
          <img class="w-5" src="@/static/image/logo.png" alt />
          <div
            class="px-1 text-white logo-title d-flex flex-column justify-content-center align-items-center"
          >
            <router-link :to="{ name: 'index' }">
              <div class="mb-0 text-center">中壬银兴</div>
              <small>ZHONGRENYINXING</small>
            </router-link>
          </div>
        </div>
        <!-- logo end-->

        <!-- 首页 联系我们 -->
        <div class="flex-fill">
          <div class="footer-right d-flex">
            <div
              class="w-50 d-flex flex-column justify-content-start text-white"
            >
              <div class="mt-2 h5">
                <router-link :to="{ name: 'index' }">首页</router-link>
              </div>
              <small class="mt-2">
                <router-link :to="{ name: 'video' }">产品服务</router-link>
              </small>
              <small class="mt-2">
                <router-link :to="{ name: 'solution' }">解决方案</router-link>
              </small>
              <small class="mt-2">
                <router-link :to="{ name: 'about' }">关于中壬银兴</router-link>
              </small>
            </div>
            <div class="w-100 d-flex flex-column justify-content-start">
              <div class="mt-2 h5">
                <router-link :to="{ name: 'index' }">联系我们</router-link>
              </div>
              <small class="mt-2" >电话：0755-82557072</small>
              <small class="mt-2" >邮箱：zryx@cnzryx.com</small>
              <small class="mt-2" >
                地址：深圳市福田区联合金融大厦A座14层
              </small>
            </div>
            <div class="d-flex flex-column text-center">
              <img src="@/static/image/erweima.png" alt="" />
              <small class="text-white">扫一扫关注我们</small>
            </div>
          </div>
        </div>
        <!-- 首页 联系我们 end-->
        <!-- 版权 -->
        <div class="footer-copyright d-flex justify-content-center align-items-center">
          <code class=" text-white"
            >中壬银兴信息技术有限公司版权所有 CopyRight © 2019,
            ZhongRenYinXing Information Technology Co.,Ltd All Rights Reserved
            粤ICP备17123278号</code
          >
        </div>
        <!-- 版权 end -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bg_image: {
        backgroundImage: "url(" + require("@/static/image/footer.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.footer-copyright{
  position: absolute;
  bottom:30px;
  color: #fff;
}
.dy-padding {
  padding-top: 100px;
}
.footer {
  height: 400px;
}
.h5 {
  a {
    padding-bottom: 2px;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      bottom: -2px;
      height: 1px;
      width: 100%;
      transform: translateX(-100%);
      background-image: linear-gradient(to right, #ffca28, #ff4081);
    }
  }
}
// .avtive{
//    color: #fff!important;
// }
</style>

