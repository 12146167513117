<template>
  <div>
    <div class="container d-flex justify-content-center align-items-center">
      <div class="items d-flex justify-content-center align-items-center">
        <div
          class="item flex-fill d-flex justify-content-center align-items-center"
          v-for="(item, index) in solution"
          :key="index"
          @mouseenter="enter(index)"
          @mouseleave="leave()"
          :class="preIndex === index ? 'active-solution ' : ''"
        >
          <img class="" :src="item.icon" alt="" />
          <span style="font-size:1.3rem;"> {{ item.title }}</span>
          <span v-if="preIndex === index" class="caret"></span>
        </div>
      </div>
    </div>
    <solution-item :index="preIndex"  ></solution-item>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/footer.vue";
import solutionItem from "@/components/solution-item.vue";
export default {
  components: {
    solutionItem,
    Footer,
  },
  data() {
    return {
      solution: [
        {
          icon: require("@/static/image/icon-1.png"),
          title: "银行",
        },
        {
          icon: require("@/static/image/icon-2.png"),
          title: "金融机构",
        },
        {
          icon: require("@/static/image/icon-3.png"),
          title: "金融从业者",
        },
        {
          icon: require("@/static/image/icon-4.png"),
          title: "小微企业",
        },
      ],
      index: 0,
      preIndex: 0,
    };
  },
  methods: {
    enter(index) {
      this.preIndex = index;
      // console.log("进入", index);
    },
    leave() {
      // console.log("离开");
    },
  },
};
</script>

<style lang="less" scoped>
.items {
  text-align: center;
  background-color: #181f4e;
  width: 65%;
  margin-top: 50px;
  margin-bottom: 50px;
  color: white;
  .item {
    position: relative;
    width: 100px;
    height: 45px;
    img {
      width: 40px;
      padding-right: 10px;
    }
    .caret {
      position: absolute;
      bottom: -12px;
      display: inline-block;
      width: 0;
      height: 0;
      // margin-left: 2px;
      vertical-align: middle;
      border-top: 12px solid;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      color: #808cec;
    }
  }
  .fade-in-left {
     animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .active-solution {
    background-color: #808cec;
    animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  @keyframes fade-in-left {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
</style>